import React, { Component } from "react";
import "../App.css";
import Swal from "sweetalert2";
import { InputGroup, Form } from "react-html5-form";
import BaseUrl from "./base-url";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";

class Login extends Component {
  handleSubmit = (form) => {
    const formData = new FormData(form.getRef().current);

    let requestData = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    };

    fetch(
      BaseUrl.url_dashbord +
        "/auth/?username=" +
        formData.get("username") +
        "&password=" +
        formData.get("password"),
      requestData
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        localStorage.setItem("token", response.headers.get("Authorization"));
        return response.json();
      })
      .then((response) => {
        this.props.history.push("/agents");
      })
      .catch((error) => {
        // Swal({
        //   title: "Error",
        //   type: "error",
        //   html: "Invalid Username or Password",
        //   showCloseButton: true
        // });

        console.log(error);
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row flex-column w-75">
          <h4 className="text-success">Sign In</h4>
          <a
            className="btn btn-outline-success px-5"
            href="http://wacot.everyfarmer.farm"
            // href="http://portal.everyfarmer.farm/?access_id=23449095"
          >
            Click to Login
          </a>
          <small>
            Forgot Password ?{" "}
            <span className="font-weight-bold">Click Here</span>
          </small>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
