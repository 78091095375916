import React, { Component } from "react";
import Login from "./login";
import Signup from "./signup";
import "../App.css";

class Home extends Component {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4 col-sm-12 order-md-1 order-2 p-3">
          <Login />
        </div> */}
          <div className="col-sm-12 order-md-2 order-1 bg p-3">
            <Signup />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
