import React, { Component } from "react";
import { Form, InputGroup } from "react-html5-form";
import Swal from "sweetalert2";
import loading from "../assets/loading.gif";
class UploadCV extends Component {
  state = {
    isUploaded: false,
    message: "",
    imageId: -1,
    isDeleteButton: false,
    isUploadingFile: false
  };
  BASE_URL =
    "http://everyfarmer.farm:8080/everyfarmer-dashboard-rest-agent-registration/resources/dashboard";
  displayError = error => {
    Swal({
      title: "Error",
      type: "error",
      html: error,
      showCloseButton: true
    });
  };
  handleFormSubmit = form => {
    // var input = document.querySelector('input[type="file"]');

    // var formData = new FormData();
    // formData.append("file", input.files[0]);
    // formData.append("user", "hubot");
    // const formData = new FormData();
    this.setState({ isUploadingFile: true });
    const formData = new FormData(form.getRef().current);

    const requestData = {
      method: "POST",
      body: formData
    };

    fetch(this.BASE_URL + "/uploads", requestData)
      .then(response => response.json())
      .then(data => {
        if (data.id > 0) {
          this.setState({
            isUploaded: true,
            message: "File uploaded successfully",
            imageId: data.id
          });

          this.props.onUpload(this.state.isUploaded, data.id);
        } else {
          this.setState({ isUploaded: false, message: "Error uploading" });
        }
        this.setState({ isUploadingFile: false });
      })
      .catch(error => {
        this.setState({ isUploadingFile: false });
        this.setState({
          isUploaded: false,
          message: "Upload Failed and Error occured"
        });

        this.displayError("Upload Failed and Error occured");
      });
  };

  render() {
    return (
      <div>
        {(this.state.isUploaded && !this.props.isDeleteCV)  ? (
          // After a successfulupload
          <button
            onClick={() => {
              this.props.onDelete(this.state.imageId);
            }}
            className="d-inline btn btn-sm btn-success border px-3"
            type="button"
          >
            Remove Uploaded CV
          </button>
        ) : (
          //Before Upload
          <button
            type="button"
            className="d-inline btn btn-sm btn-success border px-3"
            data-toggle="modal"
            data-target="#uploadCv"
          >
            Upload CV
          </button>
        )}

        <div className="col-md-10">
          <Form id="myForm" onSubmit={this.handleFormSubmit}>
            {({ error, valid, sumitted, prestine, form, submitting }) => (
              <>
                <div
                  class="modal fade"
                  id="uploadCv"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          Upload CV
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {/* BEGIN MODAL BODY */}

                      {this.state.isUploaded ? (
                        <div className="modal-body">
                          <p>{this.state.message}</p>
                        </div>
                      ) : (
                        <div className="modal-body">
                          <InputGroup tag="div" validate={["file"]}>
                            {({ error }) => (
                              <>
                                <small>Maximum file size is 1MB</small>
                                <div className="row flex-row justify-content-around my-3">
                                  <input
                                    size="1000000"
                                    accept=".pdf,.doc"
                                    required
                                    className="file-form-control col-md-6"
                                    type="file"
                                    name="file"
                                  />
                                  <button
                                    disabled={this.state.isUploadingFile}
                                    onClick={() => {}}
                                    className="btn btn-success btn-sm"
                                  >
                                    Upload
                                  </button>
                                  {this.state.isUploadingFile && (
                                    <img
                                      src={loading}
                                      width="20%"
                                      height="25%"
                                      alt="logo"
                                      className="col-md-2 d-inline"
                                    />
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-md-6">{error}</div>
                                </div>
                              </>
                            )}
                          </InputGroup>
                        </div>
                      )}

                      {/* END MODAL BODY */}
                      <div class="modal-footer">
                        {this.state.isUploaded ? (
                          <button
                            type="button"
                            class="btn btn-sm btn-success"
                            data-dismiss="modal"
                            onClick={() => {}}
                          >
                            Continue
                          </button>
                        ) : (
                          <button
                            disabled={this.state.isUploadingFile}
                            type="button"
                            class="btn btn-sm btn-danger"
                            data-dismiss="modal"
                            onClick={() => {}}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default UploadCV;
