import React, { Component } from "react";

const BaseUrl = {
  url:
    "https://portal.everyfarmer.farm:3579/everyfarmer-dashboard-rest/resources/dashboard/login",
  url_dashbord:
    "https://portal.everyfarmer.farm:3579/everyfarmer-dashboard-rest/resources/dashboard",
  // url: "http://localhost:8080/everyfarmer-dashboard-rest/api",
  // url_dashbord:
  //   "http://localhost:8080/everyfarmer-dashboard-rest/resources/dashboard"
};

export default BaseUrl;
