import React, { Component } from "react";
import logo from "../assets/egtai-logo.png";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "../App.css";
import ManageAgent from "./ManageAgent";

class Agents extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-lg-2 col-sm-2 order-md-2 order-2 p-3">
          <img
            src={logo}
            width="100%"
            height="15%"
            alt="logo"
            className="d-inline"
          />
          <h4 className="text-secondary text-center">WACOT RICE</h4>
        </div>
        <div className="col-lg-10 col-sm-10 order-md-10 order-1 bg p-3">
          <ManageAgent id={2} />
        </div>
      </div>
    );
  }
}

export default Agents;
