import React, { Component } from "react";
import { Form, InputGroup } from "react-html5-form";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import BaseUrl from "./base-url";
import $ from "jquery";
import ReactTable from "react-table";
import "react-table/react-table.css";
import FileViewer from "react-file-viewer";

export default class ManageAgent extends Component {
  state = {
    selectedFile: "",
    selectedFileType: "",
    id: 0,
    firstName: "",
    surname: "",
    othername: "",
    gender: "",
    phone: "",
    email: "",
    state: "",
    lga: "",
    town: "",
    address: "",
    states: [],
    lgs: [],
    wards: [],
    agentListActive: [],
    agentListInActive: [],
    sharedAgencyId: 0
  };

  onLogout = () => {
    localStorage.setItem("token", "");
    window.location = "/";
  };
  loadData = item => {
    console.log(item);
    fetch(BaseUrl.url_dashbord + "/uploads/" + item, {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log(data);
        this.setState({
          selectedFile: data.remoteLink,
          selectedFileType: data.fileType
        });
        console.log(this.state.selectedFileType);
      })

      .catch(error => {
        console.log(error);
      });
  };

  columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Email", accessor: "email" },
    { Header: "Town", accessor: "town" },
    {
      Header: "CV",
      accessor: "cvUpload",
      Cell: props => {
        return (
          <div>
            <a
              href="#previewDocModal"
              data-toggle="modal"
              onClick={() => this.loadData(props.value)}
            >
              view
            </a>
          </div>
        );
      }
    }
  ];

  componentDidMount() {
    if (localStorage.getItem("token") === "") {
      window.location = "/";
    }
    this.setState({
      sharedAgencyId: this.props.id
    });

    let sharedAgencyId = this.props.id;
    this.getStates();
    this.getAgentList("");
  }

  /**
   *
   * Fetch all the  from the api bysharedagency
   */
  getStates() {
    fetch(BaseUrl.url_dashbord + "/wards/states", {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => response.json())
      .then(states => {
        this.setState({
          states: states
        });
        console.log(states);
      });
  }

  activeResult = [];
  inActiveResult = [];

  getAgentList(state) {
    console.log("Called " + state);
    fetch(
      BaseUrl.url_dashbord +
        "/agents/bysharedagency/" +
        this.props.id +
        "/ACTIVE" +
        state,
      {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      }
    )
      .then(response => response.json())
      .then(res => {
        this.activeResult = [];
        res.map(data => {
          this.activeResult.push({
            name: data.firstName + " " + data.surname,
            phone: data.phone,
            email: data.email,
            town: data.town,
            cvUpload: data.cvUpload
          });
        });
        this.setState({
          agentListActive: this.activeResult
        });
      });

    fetch(
      BaseUrl.url_dashbord +
        "/agents/bysharedagency/" +
        this.props.id +
        "/INACTIVE" +
        state,
      {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      }
    )
      .then(response => response.json())
      .then(res => {
        this.inActiveResult = [];
        res.map(data => {
          this.inActiveResult.push({
            name: data.firstName + " " + data.surname,
            phone: data.phone,
            email: data.email,
            town: data.town,
            cvUpload: data.cvUpload
          });
        });

        this.setState({
          agentListInActive: this.inActiveResult
        });
      });
  }

  /**
   *
   * Fetch all the farm types from the api
   */
  getLocalGovts(state) {
    if (state === "all") {
      this.setState({
        lgs: []
      });
      return;
    }
    fetch(BaseUrl.url_dashbord + "/wards/lgs/" + state, {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => response.json())
      .then(lgs => {
        console.log(lgs);
        this.setState({
          lgs: lgs
        });
      });
  }

  /**
   *
   * Fetch all the farm types from the api
   */
  getWards(lg) {
    fetch(BaseUrl.url_dashbord + "/wards/wards/" + lg, {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    })
      .then(response => response.json())
      .then(wards => {
        this.setState({
          wards: wards
        });
        console.log(wards);
      });
  }

  // Update the value of the corresponding state field
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  // Update the value of the corresponding state field
  onValueChange = (e, type) => {
    console.log(type);
    if (type === "state") {
      this.setState({
        [e.target.name]: e.target.value
      });

      this.getLocalGovts(e.target.value);

      if (e.target.value === "all") {
        this.getAgentList("");
      } else {
        this.getAgentList("/" + e.target.value);
      }
    } else if (type === "lg") {
      this.setState({
        [e.target.name]: e.target.value
      });
      if (e.target.value === "all") {
        this.getAgentList("/" + this.state.state);
      } else {
        this.getAgentList("/" + this.state.state + "/" + e.target.value);
      }
    }
  };

  clearState() {
    this.setState({
      firstName: "",
      surname: "",
      othername: "",
      gender: "",
      phone: "",
      email: "",
      state: "",
      lga: "",
      town: "",
      address: ""
    });
  }

  setPreviews(data) {
    this.setState({
      firstName: data.firstName,
      surname: data.surname,
      othername: data.othername,
      gender: data.gender,
      phone: data.phone,
      email: data.email,
      state: data.state,
      lga: data.lga,
      town: data.town,
      address: data.address
    });
  }

  /**
   * submit Agent
   */
  submitForm = data => {
    data.preventDefault();
    let agentData = {
      id: this.state.id,
      firstName: this.state.firstName,
      surname: this.state.surname,
      othername: this.state.othername,
      gender: this.state.gender,
      phone: this.state.phone,
      email: this.state.email,
      state: this.state.state,
      lga: this.state.lga,
      town: this.state.town,
      address: this.state.address,
      sharedAgencyId: this.props.id
    };
    console.log(agentData);
    fetch(BaseUrl.url_dashbord + "/agents/saveagent/", {
      method: "POST",
      body: JSON.stringify(agentData),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        if (res.status === 0) {
          alert(res.message);
          this.getAgentList();
          this.clearState();
          $("#editModal").modal("hide");
        } else {
          alert(res.message);
        }
      })
      .catch(err => console.log(err));
  };

  render() {
    return (
      <div className="container">
        <div className="col-md-12 order-md-1">
          <div className="d-flex justify-content-end my-1">
            <button
              className="btn-sm btn-danger border px-4"
              onClick={this.onLogout}
            >
              Logout
            </button>
          </div>
          {/* <button
            disabled
            type="button"
            className="btn btn-success btn-sm my-3"
            data-toggle="modal"
            data-target="#editModal"
            onClick={() => this.clearState()}
          >
            Add Agent
          </button> */}

          <div className="form-row">
            <div className="form-group col-md-4">
              <select
                className="form-control form-control-sm"
                name="state"
                value={this.state.state}
                onChange={e => this.onValueChange(e, "state")}
              >
                <option value="all">All State</option>
                {this.state.states.map(state => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
                ;
              </select>
            </div>
            <div className="form-group col-md-4">
              <select
                className="form-control form-control-sm"
                name="lga"
                value={this.state.lga}
                onChange={e => this.onValueChange(e, "lg")}
              >
                <option value="all">All LGA</option>
                {this.state.lgs.map(lg => (
                  <option key={lg.id} value={lg.id}>
                    {lg.name}
                  </option>
                ))}
                ;
              </select>
            </div>
          </div>

          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
              >
                ({this.state.agentListActive.length}) Active Agent
              </a>
              <a
                className="nav-item nav-link"
                id="nav-profile-tab"
                data-toggle="tab"
                href="#nav-profile"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                ({this.state.agentListInActive.length}) Inactive Agent
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <ReactTable
                filterable="true"
                columns={this.columns}
                data={this.state.agentListActive}
              />
            </div>
            <div
              className="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <ReactTable
                filterable="true"
                columns={this.columns}
                data={this.state.agentListInActive}
              />
            </div>
          </div>
        </div>

        {/* Add  Modal */}
        <div className="col-md-12">
          <form id="addForm" onSubmit={e => this.submitForm(e)}>
            <div
              className="modal"
              id="editModal"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content bg-dark text-white">
                  <div className="modal-header">
                    <h5>Manage Agent</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-row">
                      <div className="form-group col-6">
                        <div>
                          <span>First Name</span>
                          <input
                            required
                            className="form-control"
                            placeholder="first Name"
                            name="firstName"
                            value={this.state.firstName}
                            onChange={e => this.onChange(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group col-6">
                        <div>
                          <span>Surname</span>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Surname"
                            name="surname"
                            value={this.state.surname}
                            onChange={e => this.onChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-6">
                        <div>
                          <span>Other name</span>
                          <input
                            required
                            className="form-control"
                            placeholder="Other name"
                            name="othername"
                            value={this.state.othername}
                            onChange={e => this.onChange(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group col-6">
                        <div>
                          <span>Phone</span>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Phone"
                            name="phone"
                            value={this.state.phone}
                            onChange={e => this.onChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-6">
                        <div>
                          <span>Email</span>
                          <input
                            required
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            onChange={e => this.onChange(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group col-6">
                        <div>
                          <span>Address</span>
                          <input
                            type="text"
                            required
                            className="form-control"
                            placeholder="Address"
                            name="address"
                            value={this.state.address}
                            onChange={e => this.onChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <span>Gender</span>
                        <select
                          className="form-control"
                          required
                          name="gender"
                          value={this.state.gender}
                          onChange={e =>
                            this.setState({ gender: e.target.value })
                          }
                        >
                          <option>Choose...</option>

                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <span>State</span>
                        <select
                          className="form-control"
                          required
                          name="state"
                          value={this.state.state}
                          onChange={e => this.onValueChange(e, "state")}
                        >
                          <option>Choose...</option>
                          {this.state.states.map(state => (
                            <option key={state.id}>{state.name}</option>
                          ))}
                          ;
                        </select>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <span>Local Government</span>
                        <select
                          className="form-control"
                          required
                          name="lga"
                          value={this.state.lga}
                          onChange={e => this.onValueChange(e, "lg")}
                        >
                          <option>Choose...</option>
                          {this.state.lgs.map(lg => (
                            <option key={lg.id}>{lg.name}</option>
                          ))}
                          ;
                        </select>
                      </div>

                      <div className="form-group col-md-6">
                        <span>Ward</span>
                        <select
                          className="form-control"
                          required
                          name="town"
                          value={this.state.ward}
                          onChange={e =>
                            this.setState({ town: e.target.value })
                          }
                        >
                          <option>Choose...</option>
                          {this.state.wards.map(ward => (
                            <option
                              key={ward.id}
                              placeholder="Select your ward"
                            >
                              {ward.name}
                            </option>
                          ))}
                          ;
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button type="submit" className="btn btn-secondary">
                      Save
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* Add  modal ends here */}

        {/* Preview  Modal */}
        <div className="col-md-12">
          <div
            className="modal"
            id="previewModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content bg-dark text-white">
                <div className="modal-header">
                  <h5>View Agent</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-6">
                      <div>
                        <span>First Name</span> :<b>{this.state.firstName}</b>
                      </div>
                    </div>

                    <div className="form-group col-6">
                      <div>
                        <span>Surname</span> :<b>{this.state.surname}</b>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <div>
                        <span>Other name</span> :<b>{this.state.othername}</b>
                      </div>
                    </div>

                    <div className="form-group col-6">
                      <div>
                        <span>Phone</span> :<b>{this.state.phone}</b>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-6">
                      <div>
                        <span>Email</span> :<b>{this.state.email}</b>
                      </div>
                    </div>

                    <div className="form-group col-6">
                      <div>
                        <span>Address</span> :<b>{this.state.address}</b>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <span>Gender</span> :<b>{this.state.gender}</b>
                    </div>

                    <div className="form-group col-md-6">
                      <span>State</span> :<b>{this.state.state}</b>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <span>Local Government</span> :<b>{this.state.lga}</b>
                    </div>

                    <div className="form-group col-md-6">
                      <span>Ward</span> :<b>{this.state.town}</b>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add  modal ends here */}

        {/* View Document  Modal */}
        <div className="col-md-12">
          <div
            className="modal"
            id="previewDocModal"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content ">
                <div className="modal-header">
                  <h5>CV</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.selectedFileType === "jpg" ? (
                    <img src={this.state.selectedFile} className="img" />
                  ) : (
                    <FileViewer
                      fileType={this.state.selectedFileType}
                      filePath={this.state.selectedFile}
                    />
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* View Document modal ends here */}
        {/* Delete  Modal */}
        <div
          className="modal"
          id="deleteFarmModal"
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-sm"
            role="document"
          >
            <div className="modal-content bg-dark text-white">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are You Sure you want to delete?</p>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={e => this.delete(e)}
                >
                  Yes
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Delete  modal ends here */}
      </div>
    );
  }
}
class TableView extends Component {
  constructor() {
    super();
    this.state = {
      options: []
    };
  }
  onChange(e) {
    // current array of options
    const options = this.state.options;
    let index;

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      options.push(+e.target.value);
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(+e.target.value);
      options.splice(index, 1);
    }

    // update the state with the new array of options
    this.setState({ options: options });
    console.log(this.state.options);
  }
  updateStatus() {
    const status = [];
    const statusType = this.props.statusType;
    this.state.options.forEach(e => {
      const val = { id: e, status: statusType };
      status.push(val);
    });
    const agentObject = { agentObject: status };

    fetch(BaseUrl.url_dashbord + "/agents/updatestatus/", {
      method: "POST",
      body: JSON.stringify(agentObject),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        console.log(res);
        if (res.code == 0) {
          alert(res.message);
          this.props.callback();
        } else {
          alert(res.message);
        }
      })
      .catch(err => console.log(err));
  }
  render() {
    return (
      <div>
        {this.state.options.length != 0 ? (
          <button
            className="btn btn-warning"
            onClick={() => this.updateStatus()}
          >
            Update {this.state.options.length} Agent Status
          </button>
        ) : null}

        <table className="table table-sm">
          <thead className="thead-dark bg-dark text-white">
            <tr>
              <th>First name</th>
              <th>Surname</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Status</th>
              <th>Edit</th>
              <th>View Details</th>
            </tr>
          </thead>
          <tbody>
            {this.props.agentList ? (
              this.props.agentList.map(res => {
                return (
                  <tr>
                    <td hidden>{res.id}</td>
                    <td>{res.firstName}</td>
                    <td>{res.surname}</td>
                    <td>{res.phone}</td>
                    <td>{res.email}</td>
                    <td>{res.status}</td>

                    {/* Open Edit  Modal  */}
                    <td>
                      <i
                        class="fas fa-edit"
                        styleClass="clickable-row"
                        onClick={() =>
                          this.setState({
                            firstName: res.firstName,
                            surname: res.surname,
                            othername: res.othername,
                            gender: res.gender,
                            phone: res.phone,
                            email: res.email,
                            state: res.state,
                            lga: res.lga,
                            town: res.town,
                            address: res.address
                          })
                        }
                        data-toggle="modal"
                        data-target="#editModal"
                      />
                    </td>

                    {/* view assets */}
                    <td>
                      <i
                        class="fas fa-eye"
                        styleClass="clickable-row"
                        data-toggle="modal"
                        data-target="#previewModal"
                        onClick={() => this.setPreviews(res)}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        value={res.id}
                        onChange={this.onChange.bind(this)}
                      />
                      make {this.props.statusType}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>Loading data...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
