import React, { Component } from "react";
import Login from "./components/login";
import Signup from "./components/signup";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Agents from "./components/agents";
import Home from "./components/home";
import { unregister } from "./components/fetch-interceptor";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="container-fluid">
          <Route exact path="/" component={Home} />
          <Route path="/agents" component={Agents} />

          <div className="row d-flex justify-content-center my-2">
            <p>
              © Copyright{" "}
              <span className="green">
                <strong>Every</strong>
              </span>
              <span className="greener">
                <strong>Farmer </strong>
              </span>
              2021. All right reserved
            </p>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
