import fetchIntercept from "fetch-intercept";

export const unregister = fetchIntercept.register({
  request: function (url, config) {
    console.log("URL " + url);
    // Modify the url or config here
    console.log("TOKEN = " + localStorage.getItem("token"));
    if (
      url.includes(
        "https://portal.everyfarmer.farm:3579/everyfarmer-dashboard-rest/resources/dashboard/auth"
      )
    ) {
      config.headers.Authorization = localStorage.getItem("token");
    }

    console.log("URL " + url);
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});
